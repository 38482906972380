import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Services`}</h2>
    <ProjectCard link="https://ratee.nam-club.com/" mdxType="ProjectCard">
      <p style={{
        "fontSize": "200%",
        "fontWeight": "bold"
      }}>ratee</p>
      <img src="ratee_icon.png" alt="numbuyer" title="ratee" style={{
        "width": "100%"
      }} /><br />
rateeは、アカウント作成不要で誰でも気軽にアンケートを使って世の中の意見を探す・見る・集めることができるアプリです。
    </ProjectCard>
    <ProjectCard link="https://practical-kirch-5024e4.netlify.app/" mdxType="ProjectCard">
      <p style={{
        "fontSize": "200%",
        "fontWeight": "bold"
      }}>【停止中】NumBuyer</p>
      <img src="numbuyer_icon.png" alt="numbuyer" title="numbuyer" style={{
        "width": "100%"
      }} /><br />
リアルタイムで全国のプレイヤー、フレンドと勝負できるボードゲームアプリ。<br />
計算(Num)とオークション(Buyer)の要素を兼ね備えた新感覚の楽しさが特徴です。<br />
    </ProjectCard>
    <ProjectCard mdxType="ProjectCard">
      <p style={{
        "fontSize": "200%",
        "fontWeight": "bold"
      }}>【停止中】 Warotan</p>
      <img src="warotan_icon1.png" alt="warotan" title="warotan" style={{
        "width": "50%"
      }} /><br />
すぐボケれる。<br />
リアルタイムにボケて、リアクションをすることができる大喜利アプリです。
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      